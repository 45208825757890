function getCookieValue(cookieName: string): string {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

const cookieValue = getCookieValue('cookie_law');

if (cookieValue !== 'cookieRejectMarketing') {
    const mainScript = document.createElement('script');

    mainScript.async = true;
    mainScript.crossOrigin = 'anonymous';
    mainScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5928161074779380';

    document.body.appendChild(mainScript);
}

export default {};
